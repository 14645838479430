<template>
  <div>工作台</div>
</template>
<script>
export default {
  name: 'Header',
  components: {
  },
  data(){
    return {
    }
  },
  created: function () {
  },
  computed: {
  },
  watch: {
  },
  mounted: function(){
    console.log('工作台 mounted')
  },
  destroyed: function(){
    console.log('工作台 destroyed')
  },
  methods: {
  },
}
</script>